
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import {getPGITravelTicketImages} from "@/api/pgi";

export default defineComponent({
  name: "reorder",
  components: {
  },
  data() {
    return { 
      list : [],
      pagination: {
        total: 0,
        per_page: 10,    // required
        current_page: 1, // required
        last_page: 0,    // required
        from: 1,
        to: 12,
      },
      loading: true,
      windowWidth: window.innerWidth,
      isMobile : false,
    }
  },
  mounted(){
      this.reorderhistory();

      this.windowWidth = window.innerWidth;
      if(this.windowWidth <= 450){
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
      console.log(this.windowWidth);
      console.log(this.isMobile);
  },
  methods : {
    async reorderhistory(){
      this.loading = true;

      
      const response = await getPGITravelTicketImages();
      console.log(response.data);
      this.list = response.data.pgiTravelImages;
      this.loading = false;
    },
  },
  setup() {
    const tabIndex = ref(0);

    
  }
});
