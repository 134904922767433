import request from '@/utils/request'

//Get PGI Model Data
export function getPGIModelData(pgiPage) {
  return request({ url: 'vgetpgimodeldata', method: 'post', data: {"pgiPage" : pgiPage} })
} 




// Claim PGI
export function claimPGI(params) {
  return request({ url: 'vclaimpgi', method: 'post', data: params })
} 


export function getPGITravelTicketImages() {
  return request({ url: 'getimage/pgitraveltickets', method: 'post', data: {"x" : "x"} })
} 