import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "col-xxl-12 mb-5" }
const _hoisted_2 = { class: "card" }
const _hoisted_3 = { class: "rounded border p-10" }
const _hoisted_4 = {
  id: "kt_carousel_1_carousel_pc",
  class: "carousel carousel-custom slide",
  "data-bs-ride": "carousel",
  "data-bs-interval": "2000"
}
const _hoisted_5 = { class: "d-flex align-items-center justify-content-between flex-wrap" }
const _hoisted_6 = { class: "carousel-inner pt-8 text-center" }
const _hoisted_7 = { class: "card-body py-3" }
const _hoisted_8 = { class: "col-md-12" }
const _hoisted_9 = { class: "p-0 m-0 carousel-indicators carousel-indicators-dots" }
const _hoisted_10 = {
  id: "kt_mobile_carousel_1_carousel",
  class: "carousel carousel-custom slide",
  "data-bs-ride": "carousel",
  "data-bs-interval": "2000"
}
const _hoisted_11 = { class: "d-flex align-items-center justify-content-between flex-wrap row" }
const _hoisted_12 = { class: "carousel-inner pt-8 text-center" }
const _hoisted_13 = { class: "col-xxl-12" }
const _hoisted_14 = { class: "p-0 m-0 carousel-indicators carousel-indicators-dots" }
const _hoisted_15 = {
  key: 0,
  class: "row"
}
const _hoisted_16 = { class: "card-body py-3" }
const _hoisted_17 = {
  key: 1,
  class: "row"
}
const _hoisted_18 = { class: "card-body py-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CompanyPolicy = _resolveComponent("CompanyPolicy")
  const _component_ECShopAdvisory = _resolveComponent("ECShopAdvisory")
  const _component_el_skeleton = _resolveComponent("el-skeleton")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_CompanyPolicy),
    _createVNode("div", _hoisted_1, [
      _createVNode(_component_ECShopAdvisory)
    ]),
    _withDirectives(_createVNode("div", null, [
      _createVNode(_component_el_skeleton, {
        rows: 10,
        animated: ""
      })
    ], 512), [
      [_vShow, _ctx.loading]
    ]),
    _createVNode("div", _hoisted_2, [
      _createVNode("div", _hoisted_3, [
        _withDirectives(_createVNode("div", _hoisted_4, [
          _createVNode("div", _hoisted_5, [
            _createVNode("div", _hoisted_6, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
                return (_openBlock(), _createBlock("div", {
                  key: index,
                  class: index == 0 ? 'carousel-item active' : 'carousel-item'
                }, [
                  _createVNode("div", {
                    class: [_ctx.widgetClasses, "card"]
                  }, [
                    _createVNode("div", _hoisted_7, [
                      _createVNode("img", {
                        src: item.url,
                        style: {"max-height":"500px"}
                      }, null, 8, ["src"])
                    ])
                  ], 2)
                ], 2))
              }), 128))
            ]),
            _createVNode("div", _hoisted_8, [
              _createVNode("ol", _hoisted_9, [
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
                  return (_openBlock(), _createBlock("li", {
                    key: index,
                    "data-bs-target": "#kt_carousel_1_carousel_pc",
                    "data-bs-slide-to": index,
                    class:  index == 0 ? 'ms-1 active' : 'ms-1'
                  }, null, 10, ["data-bs-slide-to"]))
                }), 128))
              ])
            ])
          ])
        ], 512), [
          [_vShow, _ctx.isMobile == false]
        ]),
        _withDirectives(_createVNode("div", _hoisted_10, [
          _createVNode("div", _hoisted_11, [
            _createVNode("div", _hoisted_12, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
                return (_openBlock(), _createBlock("div", {
                  key: index,
                  class: index == 0 ? 'carousel-item active' : 'carousel-item'
                }, [
                  _createVNode("img", {
                    src: item.url,
                    alt: 'Image' + index,
                    style: {"max-height":"300px !important"},
                    class: "mb-3"
                  }, null, 8, ["src", "alt"])
                ], 2))
              }), 128))
            ]),
            _createVNode("div", _hoisted_13, [
              _createVNode("ol", _hoisted_14, [
                (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
                  return (_openBlock(), _createBlock("li", {
                    key: index,
                    "data-bs-target": "#kt_mobile_carousel_1_carousel",
                    "data-bs-slide-to": index,
                    class: index == 0 ? 'ms-1 active' : 'ms-1'
                  }, null, 10, ["data-bs-slide-to"]))
                }), 128))
              ])
            ])
          ])
        ], 512), [
          [_vShow, _ctx.isMobile == true]
        ])
      ])
    ]),
    false
      ? (_openBlock(), _createBlock("div", _hoisted_15, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
            return _withDirectives((_openBlock(), _createBlock("div", {
              key: index,
              class: "col-xxl-4"
            }, [
              _createVNode("div", {
                class: [_ctx.widgetClasses, "card"]
              }, [
                _createVNode("div", _hoisted_16, [
                  _createVNode("img", {
                    src: item.url,
                    style: {"max-height":"500px"}
                  }, null, 8, ["src"])
                ])
              ], 2)
            ], 512)), [
              [_vShow, !_ctx.loading]
            ])
          }), 128))
        ]))
      : _createCommentVNode("", true),
    false
      ? (_openBlock(), _createBlock("div", _hoisted_17, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.list, (item, index) => {
            return _withDirectives((_openBlock(), _createBlock("div", {
              key: index,
              class: "col-xxl-4"
            }, [
              _createVNode("div", {
                class: [_ctx.widgetClasses, "card"]
              }, [
                _createVNode("div", _hoisted_18, [
                  _createVNode("img", {
                    src: item.url,
                    style: {"max-height":"350px"}
                  }, null, 8, ["src"])
                ])
              ], 2)
            ], 512)), [
              [_vShow, !_ctx.loading]
            ])
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ], 64))
}